import _ from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Card,
  Grid,
  InfoCardForm,
  InfoCardFormProps,
  Loading,
  Box,
  Typography,
  Chip,
} from 'onescreen/components';
import { Button, ButtonProps } from 'onescreen/components';

import {
  MonitoringPlatformItem,
  MonitoringPlatformCreateForm,
} from '../monitoring/monitoringPlatform';
import { MeterItem, MeterAttachForm } from '../monitoring/meterItem';
import { ServiceDropDownloadModal } from './intervalDownloadModal';
import { IntervalFileBar } from '../../common/components/Interval';

import { ServiceDrop, SolarGenerator, Storage } from '../../common/models';
import { useDialogState, useServiceDrop } from '../../common/hooks';
import { BaselineCard, BaselineForm } from 'onescreen/components/BaselineForm';
import { HasUserPermissions } from 'onescreen/hooks/user';
import { ServiceDropParam } from 'onescreen/types';

export default function ServiceDropReport() {
  const { sdId } = useParams<ServiceDropParam>() as ServiceDropParam;
  const [showAddSolar, setShowAddSolar] = useState(false);
  const [showAddStorage, setShowAddStorage] = useState(false);
  const [showAddMeter, setShowAddMeter] = useState(false);
  const [baselineFormOpen, openBaselineForm, closeBaselineForm] = useDialogState();
  const [downloadModalOpen, openDownloadModal, closeDownloadModal] = useDialogState();

  const { serviceDrop, loading } = useServiceDrop(+sdId, {
    include: [
      'meters.interval_file',
      'demo_meters.interval_file',
      'solar_generator.baselines.*',
      'storage.*',
      'site.org.*',
      'generation_benefit_choices',
    ],
  });

  const hasPermission = HasUserPermissions(['OrgEditors', 'OrgFullAccessEditors']);

  if (loading || !serviceDrop) return <Loading />;

  const solarGenerator = serviceDrop.SolarGenerator;
  const storage = serviceDrop.Storage;

  const addBtn = (onClick: ButtonProps['onClick']) => (
    <Button onClick={onClick} icon="plus" color="secondary" />
  );

  const onClickAddSolar = () => {
    setShowAddSolar(true);
  };
  const addSolarBtn = addBtn(onClickAddSolar);
  const onAddSolarMonitor = () => {
    setShowAddSolar(false);
  };

  const solarId = serviceDrop?.solar_generator;
  let solarPane = undefined;
  if (solarId) {
    let monitorList: JSX.Element | JSX.Element[] = addSolarBtn;
    if (solarGenerator?.monitors) {
      monitorList = _.truthy(solarGenerator.monitors).map((monitorId) => (
        <MonitoringPlatformItem key={monitorId} mpId={monitorId} />
      ));
    }
    solarPane = (
      <Grid>
        <Grid.Item>
          <BaselineCard
            objWithBaselines={solarGenerator!}
            baselineType="Production"
            hasPermission={hasPermission}
            openBaselineForm={openBaselineForm}
          />
        </Grid.Item>
        {solarGenerator?.interval_file && (
          <Grid.Item span={12}>
            <Card>
              <Typography>Cleaned Intervals</Typography>
              <IntervalFileBar id={solarGenerator?.interval_file} title="Cleaned Solar data" />
            </Card>
          </Grid.Item>
        )}
        {monitorList}
        {!showAddSolar && <Grid.Item span={12}>{addSolarBtn}</Grid.Item>}
        {showAddSolar && (
          <Grid.Item span={12}>
            <MonitoringPlatformCreateForm
              device="solar_generator"
              deviceId={solarId}
              onAdd={onAddSolarMonitor}
              onCancel={() => {
                setShowAddSolar(false);
              }}
            />
          </Grid.Item>
        )}
        <BaselineForm
          objWithBaselines={solarGenerator!}
          dialogOpen={baselineFormOpen}
          closeDialog={closeBaselineForm}
          updateBaselines={(body) => {
            if (serviceDrop.solar_generator) {
              SolarGenerator.api.update(serviceDrop.solar_generator, body, {
                include: ['baselines.*'],
              });
            }
          }}
        />
      </Grid>
    );
  }

  const onClickAddStorage = () => {
    setShowAddStorage(true);
  };
  const addStorageBtn = addBtn(onClickAddStorage);

  const onAddStorageMonitor = () => {
    setShowAddStorage(false);
  };

  const storageId = serviceDrop?.storage;
  let storagePane = undefined;
  if (storageId) {
    let monitorList: JSX.Element | JSX.Element[] = addStorageBtn;
    if (storage?.monitors) {
      monitorList = _.truthy(storage.monitors).map((monitorId) => (
        <MonitoringPlatformItem key={monitorId} mpId={monitorId} />
      ));
    }
    storagePane = (
      <Grid>
        {storage?.interval_file && (
          <Grid.Item span={12}>
            <Card>
              <Typography>Cleaned Intervals</Typography>
              <IntervalFileBar id={storage?.interval_file} title="Cleaned Storage data" />
            </Card>
          </Grid.Item>
        )}
        {monitorList}
        {!showAddStorage && <Grid.Item span={12}>{addStorageBtn}</Grid.Item>}
        {showAddStorage && (
          <Grid.Item span={12}>
            <MonitoringPlatformCreateForm
              device="storage"
              deviceId={storageId}
              onAdd={onAddStorageMonitor}
              onCancel={() => setShowAddStorage(false)}
            />
          </Grid.Item>
        )}
      </Grid>
    );
  }

  const onClickAddMeter = () => {
    setShowAddMeter(true);
  };
  let addMeterBtn = addBtn(onClickAddMeter);
  let metersPane = undefined;
  const site = serviceDrop?.Site;

  const meters = _.truthy(serviceDrop?.Meters! || []).map((meter) => (
    <Grid.Item key={meter?.id} span={12}>
      <MeterItem meterId={meter?.id} serviceDrop={serviceDrop} />
    </Grid.Item>
  ));
  const meterForm = (
    <MeterAttachForm
      site={site!}
      serviceDrop={serviceDrop!}
      onCancel={() => setShowAddMeter(false)}
      visible={showAddMeter}
      onAddMeter={() => {
        setShowAddMeter(false);
      }}
    />
  );
  metersPane = (
    <Grid>
      {serviceDrop?.interval_file && (
        <Grid.Item span={12}>
          <Card>
            <Typography>Cleaned Intervals</Typography>
            <IntervalFileBar id={serviceDrop?.interval_file} title="Cleaned Utility data" />
          </Card>
        </Grid.Item>
      )}
      {meters}
      {!showAddMeter && <Grid.Item span={12}>{addMeterBtn}</Grid.Item>}
      <Grid.Item span={12}>{site && serviceDrop && meterForm}</Grid.Item>
    </Grid>
  );

  const updateServiceDrop = async (payload: ServiceDrop.API.UpdateParams) => {
    let updateData = _.clone(payload);
    if (updateData.solar_generator && !serviceDrop?.solar_generator) {
      const { solarGenerator } = await SolarGenerator.api.create();
      updateData.solar_generator = solarGenerator.id;
    } else if (!updateData.solar_generator && serviceDrop?.solar_generator) {
      updateData.solar_generator = null;
    } else {
      delete updateData.solar_generator;
    }
    if (updateData.storage && !serviceDrop?.storage) {
      const { storage } = await Storage.api.create();
      updateData.storage = storage.id;
    } else if (!updateData.storage && serviceDrop?.storage) {
      updateData.storage = null;
    } else {
      delete updateData.storage;
    }
    ServiceDrop.api.update(serviceDrop?.id!, updateData);
  };

  const infoCardItems = [
    {
      label: 'Service Drop Name',
      value: serviceDrop?.name,
      key: 'name',
      largerText: true,
    },
    {
      label: 'ID',
      value: serviceDrop?.id,
      key: 'id',
      readOnly: true,
    },
    {
      label: 'Address',
      value: serviceDrop?.address,
      key: 'address',
    },
    {
      label: 'Generation Benefit',
      value: serviceDrop?.generation_benefit,
      key: 'generation_benefit',
      type: 'select',
      selectOptions: {
        choices: (serviceDrop?.generation_benefit_choices! || []).map(([value, label]) => ({
          value,
          label,
        })),
        allowBlank: true,
      },
    },
    {
      label: 'Solar System?',
      value: !!serviceDrop?.solar_generator,
      key: 'solar_generator',
      type: 'checkbox',
    },
    {
      label: 'Storage System?',
      value: !!serviceDrop?.storage,
      key: 'storage',
      type: 'checkbox',
    },
  ];
  return (
    <Grid.Item span={12}>
      <Card>
        <Card.Content>
          <Grid justify="flex-end">
            <Grid.Item>
              <Button color="secondary" onClick={openDownloadModal} icon="download">
                Intervals
              </Button>
            </Grid.Item>
          </Grid>

          <Box padding={5}>
            <InfoCardForm
              items={infoCardItems as InfoCardFormProps['items']}
              callback={hasPermission ? updateServiceDrop : undefined}
            />
          </Box>

          <Grid>
            <Grid.Item span={4}>
              <Chip label="Utility" icon="plug" color="primary" />
              <hr />
              {metersPane}
            </Grid.Item>
            <Grid.Item span={4}>
              <Chip label="Solar Generator" icon="sun" color={solarId ? 'primary' : 'default'} />
              <hr />
              {solarPane}
            </Grid.Item>
            <Grid.Item span={4}>
              <Chip label="Storage" icon="battery" color={storageId ? 'primary' : 'default'} />
              <hr />
              {storagePane}
            </Grid.Item>
          </Grid>
        </Card.Content>
      </Card>
      {downloadModalOpen && (
        <ServiceDropDownloadModal
          serviceDropId={serviceDrop?.id!}
          isOpen={downloadModalOpen}
          onClose={closeDownloadModal}
        />
      )}
    </Grid.Item>
  );
}
