import * as React from 'react';
import AccountIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import ArrowBack from '@material-ui/icons/ArrowBack';
import BatteryChargingFull from '@material-ui/icons/BatteryChargingFull';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import EvStation from '@material-ui/icons/EvStation';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FeedbackIcon from '@material-ui/icons/Feedback';
import InfoIcon from '@material-ui/icons/Info';
import LaunchIcon from '@material-ui/icons/Launch';
import MenuIcon from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import PowerIcon from '@material-ui/icons/Power';
import Schedule from '@material-ui/icons/Schedule';
import SendIcon from '@material-ui/icons/Send';
import SvgIcon from '@material-ui/core/SvgIcon';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import UploadIcon from '@material-ui/icons/CloudUpload';
import WbSunny from '@material-ui/icons/WbSunny';
import Warning from '@material-ui/icons/Warning';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import FilterList from '@material-ui/icons/FilterList';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Check from '@material-ui/icons/Check';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import NewReleases from '@material-ui/icons/NewReleases';
import Refresh from '@material-ui/icons/Refresh';

import { MaterialColor, materialColors } from 'onescreen/styles';

/** ======================== Constants ===================================== */
const DEFAULT_VIEW_BOX = '0 0 24 24';

/** ======================== Types ========================================= */
type IconColor = 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error';
type SvgIconWithViewBox = typeof SvgIcon & { viewBox?: string };

export type ValidIcon =
  | 'account'
  | 'back'
  | 'battery'
  | 'checkMark'
  | 'chevronLeft'
  | 'chevronRight'
  | 'clock'
  | 'close'
  | 'download'
  | 'ev_station'
  | 'expand'
  | 'feedback'
  | 'info'
  | 'launch'
  | 'menu'
  | 'pencil'
  | 'plug'
  | 'plus'
  | 'send'
  | 'sun'
  | 'refresh'
  | 'trash'
  | 'upload'
  | 'verticalDots'
  | 'warning'
  | 'radio_button_unchecked'
  | 'filterList'
  | 'lock'
  | 'lock_open'
  | 'checked'
  | 'unchecked'
  | 'radio_button_checked'
  | 'new_releases';

export type IconProps = {
  color?: IconColor | MaterialColor;
  name: ValidIcon;
  size?: 'small' | 'large' | number;
  className?: string;
};

/** ======================== Components ==================================== */
export const Icon: React.ComponentType<IconProps> = React.forwardRef<SVGSVGElement, IconProps>(
  function OneScreenIcon({ color, name, size, ...rest }, ref) {
    const IconComponent = iconMap[name];
    const colorProps = isMaterialColor(color)
      ? { htmlColor: materialColors[color][500] }
      : { color };

    const sizeProps = {
      fontSize: typeof size === 'string' ? size : undefined,
      style: typeof size === 'number' ? { fontSize: size } : undefined,
      viewBox: IconComponent.viewBox || DEFAULT_VIEW_BOX,
    };

    return <IconComponent ref={ref} {...colorProps} {...sizeProps} {...rest} />;
  }
);

/** ======================== Helpers ======================================= */
/**
 * Maps a valid icon name to the corresponding icon component
 */
const iconMap: Record<ValidIcon, SvgIconWithViewBox> = {
  account: AccountIcon,
  back: ArrowBack,
  battery: BatteryChargingFull,
  checkMark: DoneIcon,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  clock: Schedule,
  close: CloseIcon,
  download: SystemUpdateAltIcon,
  ev_station: EvStation,
  expand: ExpandMoreIcon,
  feedback: FeedbackIcon,
  info: InfoIcon,
  launch: LaunchIcon,
  menu: MenuIcon,
  pencil: CreateIcon,
  plug: PowerIcon,
  plus: AddIcon,
  send: SendIcon,
  sun: WbSunny,
  refresh: Refresh,
  trash: DeleteIcon,
  upload: UploadIcon,
  verticalDots: MoreVert,
  warning: Warning,
  radio_button_unchecked: RadioButtonUnchecked,
  filterList: FilterList,
  lock: Lock,
  lock_open: LockOpen,
  checked: Check,
  unchecked: CheckBoxOutlineBlankIcon,
  radio_button_checked: RadioButtonChecked,
  new_releases: NewReleases,
};

function isMaterialColor(color?: IconColor | MaterialColor): color is MaterialColor {
  return !!color && color in materialColors;
}
